<template>
  <div>
    <el-tabs v-model="name" @tab-click="handleClick">
      <el-tab-pane label="明细对账" name="first">
        <el-form ref="form" :model="form" label-width="100px" style="display: flex">
          <div class="el-lt" style="width: 85%">
            <el-col :span="8">
              <el-form-item label="STYLE" :class="$i18n.locale">
                <el-select v-model="form.style" value-key="id" clearable filterable>
                  <el-option
                    v-for="item in styleOptions"
                    :key="item.id"
                    :label="item.styleName"
                    :value="item.styleName"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="COLOR" :class="$i18n.locale">
                <el-select v-model="form.color" value-key="id" clearable filterable @visible-change="_queryColorList">
                  <el-option
                    v-for="item in colorOptions"
                    :key="item.id"
                    :label="item.colorName"
                    :value="item.colorName"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="SIZE" :class="$i18n.locale">
                <el-select v-model="form.size" value-key="id" clearable filterable @visible-change="_querySizeList">
                  <el-option v-for="item in sizeOptions" :key="item.id" :label="item.size" :value="item.size" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="FNSKU/UPC">
                <el-input v-model="form.fnskupc" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="供应商">

                <el-select v-model="form.vendorId" value-key="id" collapse-tags clearable filterable multiple>
                  <el-option
                    v-for="item in vendorOptions"
                    :key="item.id"
                    :label="item.vendorName"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="采购订单" :class="$i18n.locale">
                <el-input v-model="form.purchaseOrderCode" :placeholder="$t('page.inputPlaceholder')" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="对账模式" :class="$i18n.locale">
                <el-select v-model="form.model" :placeholder="$t('page.selectPlaceholder')">
                  <el-option label="勾选模式" :value="0" />
                  <el-option label="条件模式" :value="1" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="收货时间" :class="$i18n.locale">
                <el-date-picker
                  v-model="value1"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="~"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                />
              </el-form-item>
            </el-col>
          </div>
          <el-row class="el-rt" style="width: 25%">
            <el-col class="row-center">
              <el-button type="primary" @click="queryDetail(0)">{{
                $t("page.search")
              }}</el-button>
              <el-button @click="resetClick()">{{
                $t("page.reset")
              }}</el-button>
              <el-button type="text" @click="showRowClick()">{{
                showRow ? $t("page.hide") : $t("page.expand")
              }}</el-button>
            </el-col>
          </el-row>
        </el-form>
        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <!-- 明细对账导出 -->
            <ImportButton export-key="scm_not_check_bill_detail" :params="queryDetailParams" />
            <!-- 导入 -->
            <el-upload
              ref="uploadForm"
              action=""
              class="el-button padding-none"
              :http-request="uploadFile"
              :limit="1"
              :show-file-list="false"
              :file-list="fileList"
              :auto-upload="false"
              :on-change="fileChange"
            >
              <el-button v-permission="'import'" type="primary">导入</el-button>
            </el-upload>
            <el-button v-permission="'detailCheck'" type="primary" @click="detailCheck">对账确认</el-button>
          </el-col>
        </el-row>
        <detail-table
          :table-loading="tableLoading"
          :table-height="tableHeight"
          :table-data="tableDetailDatas"
          :pager="pagerDetail"
          :model="form.model"
          @refresh="queryDetail"
          @getMultipleSelection="getMultipleSelection"
        />
      </el-tab-pane>
      <el-tab-pane label="未到货未付" name="second">
        <el-form ref="noForm" :model="noForm" label-width="100px" style="display: flex">
          <div class="el-lt" style="width: 85%">
            <el-col :span="8">
              <el-form-item label="STYLE" :class="$i18n.locale">
                <el-select v-model="noForm.style" value-key="id" clearable filterable>
                  <el-option
                    v-for="item in styleOptions"
                    :key="item.id"
                    :label="item.styleName"
                    :value="item.styleName"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="COLOR" :class="$i18n.locale">
                <el-select v-model="noForm.color" value-key="id" clearable filterable @visible-change="_queryColorList">
                  <el-option
                    v-for="item in colorOptions"
                    :key="item.id"
                    :label="item.colorName"
                    :value="item.colorName"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="SIZE" :class="$i18n.locale">
                <el-select v-model="noForm.size" value-key="id" clearable filterable @visible-change="_querySizeList">
                  <el-option v-for="item in sizeOptions" :key="item.id" :label="item.size" :value="item.size" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="FNSKU/UPC">
                <el-input v-model="noForm.fnskupc" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="供应商">
                <el-select v-model="noForm.vendorId" value-key="id" clearable filterable multiple collapse-tags>
                  <el-option
                    v-for="item in vendorOptions"
                    :key="item.id"
                    :label="item.vendorName"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="采购订单" :class="$i18n.locale">
                <el-input v-model="noForm.purchaseOrderCode" :placeholder="$t('page.inputPlaceholder')" />
              </el-form-item>
            </el-col>
          </div>
          <el-row class="el-rt" style="width: 25%">
            <el-col class="row-center">
              <el-button type="primary" @click="query(0)">{{
                $t("page.search")
              }}</el-button>
              <el-button @click="resetClick()">{{
                $t("page.reset")
              }}</el-button>
              <el-button type="text" @click="showRowClick()">{{
                showRow ? $t("page.hide") : $t("page.expand")
              }}</el-button>
            </el-col>
          </el-row>
        </el-form>
        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <!-- 未到货未付 -->
            <ImportButton export-key="scm_not_collect_detail" :params="queryParams" />
          </el-col>
        </el-row>
        <detail-table
          :table-loading="tableLoading"
          :table-height="tableHeight"
          :table-data="tableDatas"
          :pager="pager"
          :flag="false"
          @refresh="query"
          @getMultipleSelection="getMultipleSelection"
        />
      </el-tab-pane>
      <el-tab-pane label="整单对账" name="third">
        <el-form ref="allForm" :model="allForm" label-width="100px" style="display: flex">
          <div class="el-lt" style="width: 85%">
            <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
              <el-col :span="8">
                <el-form-item label="采购订单" :class="$i18n.locale">
                  <el-input v-model="allForm.purchaseOrderCode" :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="STYLE" :class="$i18n.locale">
                  <el-select v-model="allForm.style" value-key="id" clearable multiple filterable collapse-tags>
                    <el-option
                      v-for="item in styleOptions"
                      :key="item.id"
                      :label="item.styleName"
                      :value="item.styleName"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="供应商">
                  <el-select v-model="allForm.vendorId" value-key="id" multiple clearable filterable collapse-tags>
                    <el-option
                      v-for="item in vendorOptions"
                      :key="item.id"
                      :label="item.vendorName"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-row class="el-rt" style="width: 25%">
            <el-col class="row-center">
              <el-button type="primary" @click="queryAll(0)">{{
                $t("page.search")
              }}</el-button>
              <el-button @click="resetClick()">{{
                $t("page.reset")
              }}</el-button>
            </el-col>
          </el-row>
        </el-form>
        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <!-- 整单对账 -->
            <ImportButton export-key="scm_not_check_bill" :params="queryAllParams" />
            <el-button v-permission="'detailCheck'" type="primary" @click="check()">对账确认</el-button>
          </el-col>
        </el-row>
        <all-table
          :table-loading="tableLoading"
          :table-height="tableHeight"
          :table-data="tableAllDatas"
          :pager="pagerAll"
          @refresh="queryAll"
          @getMultipleSelection="getMultipleSelection"
        />
      </el-tab-pane>
    </el-tabs>
    <!-- 整单对账确认弹框 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>本次对账{{ ammount }}数量，对账{{ sum }}金额，请认真核对!</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="Loading" @click="handleAllReconciliation">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 明细对账确认弹框 -->
    <el-dialog title="提示" :visible.sync="detailDialogVisible" width="30%">
      <span>本次对账{{ detailAmount }}数量，对账{{
        detailSum
      }}金额，请认真核对!</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detailDialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="detailLoading" @click="handleDetailReconciliation">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog
      title="导入"
      :visible.sync="importDialog"
      width="550px"
      :close-on-click-modal="false"
      :before-close="closeImportDialog"
    >
      <span v-if="fileList != ''" class="info-message">{{ $t("page.ConfirmImport") }} {{ fileList[0].name }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeImportDialog">取消</el-button>
        <el-button type="primary" @click="importDialogSubmit">导入详情</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="uploadTableVisible" width="80%" title="导入详情" append-to-body>
      <el-table
        ref="uploadTableDataRef"
        :data="importdata"
        :row-class-name="tableRowClassNames"
        :header-cell-style="{ background: '#fafafa' }"
        tooltip-effect="dark"
        max-height="550px"
        @selection-change="dialogSelectionChange"
      >
        <el-table-column type="selection" width="60" align="center" />
        <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
          <template slot-scope="scope">{{
            (pager.current - 1) * pager.size + scope.$index + 1
          }}</template>
        </el-table-column>
        <el-table-column prop="errorMsg" label="错误信息" />
        <el-table-column prop="purchaseOrderCode" label="po订单" />
        <el-table-column prop="collectCode" label="收货单号" />
        <el-table-column prop="vendorName" label="供应商" />
        <el-table-column prop="style" label="style" />
        <el-table-column prop="color" label="color" />
        <el-table-column prop="size" label="size" />
        <el-table-column prop="fnsku" label="fnsku" />
        <el-table-column prop="upc" label="upc" />
        <el-table-column prop="collectDate" label="收货时间" />
        <el-table-column prop="purchasePrice" label="采购单价" />
        <el-table-column prop="currPrice" label="实际对账单价" />
        <el-table-column prop="unCheckBillNumber" label="对账数量" />
        <el-table-column prop="currTotalPrice" label="对账金额" />
      </el-table>
      <el-row type="flex" justify="end" style="margin-top: 20px">
        <el-button @click="uploadTableVisible = false">取消</el-button>
        <el-button type="primary" :loading="Loading" @click="submitImportTable">提交</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { omit } from 'lodash'
import detailTable from './components/detailTable'
import allTable from './components/allTable'
import ImportButton from '@/components/ExportFile'
import { queryVendorList, queryStyleList, querySizeList, queryColorList, notCheckBillDetailPage, confirmCheckBillDetail, notCheckBillPage, confirmCheckBill, notCollectDetailPage, batchUpdateActualPriceImportView, batchUpdateActualPriceImport, confirmCheckBillDetailBySum, confirmCheckBillDetailByNotCheck } from '@/api/scm-api'
export default {
  components: { detailTable, allTable, ImportButton },
  data() {
    return {
      dialogselectlist: [],
      fileList: [],
      file: '',
      importDialog: false,
      uploadTableVisible: false,
      importdata: [],
      ID: '',
      detailAmount: 0,
      detailSum: 0,
      ammount: 0,
      sum: 0,
      detailDialogVisible: false,
      dialogVisible: false,
      tableHeight: '350',
      showRow: false,
      name: 'first',
      value1: [],
      vendorOptions: [],
      allForm: {},
      form: {
        model: 0
      },
      noForm: {},
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      tableDetailDatas: [],
      tableAllDatas: [],
      tableDatas: [],
      pagerDetail: {
        total: 0,
        size: 100,
        current: 1
      },
      pagerAll: {
        total: 0,
        size: 10,
        current: 1
      },
      pager: {
        total: 0,
        size: 10,
        current: 1
      },
      tableLoading: false,
      detailLoading: false,
      Loading: false,
      multipleSelection: [],
      code: 0
    }
  },
  computed: {
    queryDetailModelParams() {
      return omit(this.queryDetailParams, Object.keys(this.pagerDetail))
    },

    queryDetailParams() {
      const [startCollectDate, endCollectDate] = this.value1 || []
      const { vendorId } = this.form
      return Object.assign({}, this.form, { vendorId: vendorId?.toString() }, this.pagerDetail, { startCollectDate, endCollectDate })
    },
    queryAllParams() {
      const { style, vendorId } = this.allForm
      return Object.assign({}, this.allForm, { style: style?.toString(), vendorId: vendorId?.toString() }, this.pagerAll)
    },
    queryParams() {
      const { vendorId } = this.noForm
      return Object.assign({}, this.noForm, { vendorId: vendorId?.toString() }, this.pager)
    }

  },
  created() {

  },
  mounted() {
    this._queryVendorList()
    this._queryStyleList()
    this.name === 'third' ? this.queryAll(0) : this.name === 'first' ? this.queryDetail(0) : this.query(0)
  },
  methods: {
    tableRowClassNames({ row, rowIndex }) {
      if (row.errorMsg) {
        return 'warning-row'
      }
      return ''
    },
    closeImportDialog() {
      this.file = ''
      this.fileList = []
      this.importDialog = false
    },
    importDialogSubmit() {
      this.$refs.uploadForm.submit()
    },
    async submitImportTable() {
      if (this.dialogselectlist.length === 0) {
        this.$message({
          message: '请至少选择一条明细导入',
          type: 'warning'
        })
        return false
      }

      if (this.dialogselectlist.find(item => item.errorMsg)) {
        this.$confirm('列表中有错误信息不允许提交', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      const { code, msg } = await batchUpdateActualPriceImport(this.dialogselectlist)
      code === 0 && this.$notify({
        title: msg,
        message: msg,
        type: 'success'
      })
      this.uploadTableVisible = false
      // 刷新列表
      this.queryDetail(0)
    },
    dialogSelectionChange(val) {
      this.dialogselectlist = []
      this.dialogselectlist = val
    },
    async uploadFile() {
      const form = new FormData()
      form.append('file', this.file)
      const { datas } = await batchUpdateActualPriceImportView(form)
      this.importdata = datas
      console.log(this.importdata)
      this.uploadTableVisible = true
      // 隐藏弹窗清空数据
      this.file = ''
      this.fileList = []
      this.importDialog = false
    },
    fileChange(file, fileList) {
      this.fileList = fileList
      this.file = file.raw
      if (this.fileList.length !== 0) {
        if (this.beforeUpload(file)) {
          this.importDialog = true
        }
      } else {
        this.importDialog = false
      }
    },
    beforeUpload(file) {
      const Xls = file.name.split('.')
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        this.$message.error(this.$t('page.UploadSizeTips'))
        return false
      }
      if (Xls[Xls.length - 1] === 'xls' || Xls[Xls.length - 1] === 'xlsx') {
        return true
      } else {
        this.$message.error(this.$t('page.UploadTypeTips'))
        return false
      }
    },
    async detailCheck() {
      this.detailSum = 0
      this.detailAmount = 0
      if (!this.form.model) {
        if (this.multipleSelection.length === 0) {
          this.$message.warning('请至少选中一条数据')
          return
        }
        for (var i = 0; i < this.multipleSelection.length; i++) {
          this.detailSum += Number(this.multipleSelection[i].currTotalPrice)
          this.detailAmount += this.multipleSelection[i].unCheckBillNumber
        }
        this.detailSum = this.detailSum.toFixed(2)
        this.detailDialogVisible = true
      } else {
        const { datas: { totalPairs, totalPrice }} = await confirmCheckBillDetailBySum(this.queryDetailModelParams)
        this.detailSum = totalPrice
        this.detailAmount = totalPairs
        this.detailDialogVisible = true
      }
    },
    check() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请至少选中一条数据')
        return
      }
      this.ammount = 0
      this.sum = 0
      for (var i = 0; i < this.multipleSelection.length; i++) {
        this.sum += this.multipleSelection[i].unCheckBillPrice
        this.ammount += this.multipleSelection[i].unCheckBillNumber
      }

      this.sum = this.sum.toFixed(2)
      this.dialogVisible = true
    },
    getMultipleSelection(val) {
      // console.log(val);
      this.multipleSelection = val
    },
    resetClick() {
      this.value1 = []
      this.name === 'third' ? this.allForm = {} : this.name === 'first' ? this.form = {} : this.noForm = {}
      this.name === 'third' ? this.queryAll(0) : this.name === 'first' ? this.queryDetail(0) : this.query(0)
    },
    // 未到货未付分页查询
    async query(type) {
      try {
        type === 0 ? this.pager.current = 1 : ''
        this.tableLoading = true
        const { datas } = await notCollectDetailPage(this.queryParams)
        const { pager, records } = datas
        this.pager = pager
        this.tableDatas = records
      } finally {
        this.tableLoading = false
      }
    },
    // 整单明细查询
    async queryAll(type) {
      try {
        type === 0 ? this.pagerAll.current = 1 : ''
        this.tableLoading = true
        const { datas } = await notCheckBillPage(this.queryAllParams)
        const { pager, records } = datas
        this.pagerAll = pager
        this.tableAllDatas = records
      } finally {
        this.tableLoading = false
      }
    },
    // 明细分页查询
    async queryDetail(type) {
      try {
        type === 0 ? this.pagerDetail.current = 1 : ''
        this.tableLoading = true
        const { datas } = await notCheckBillDetailPage(this.queryDetailParams)
        const { pager, records } = datas
        this.pagerDetail = pager
        this.tableDetailDatas = records
      } finally {
        this.tableLoading = false
      }
    },
    // size
    async _querySizeList() {
      if (this.name === 'first') {
        const { id } = this.styleOptions.find(item => item.styleName === this.form.style) || []
        this.ID = id
      } else {
        const { id } = this.styleOptions.find(item => item.styleName === this.noForm.style) || []
        this.ID = id
      }
      const { datas } = await querySizeList({ styleId: this.ID })
      this.sizeOptions = datas
    },
    // style
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // color
    async _queryColorList() {
      if (this.name === 'first') {
        const { id } = this.styleOptions.find(item => item.styleName === this.form.style) || []
        this.ID = id
      } else {
        const { id } = this.styleOptions.find(item => item.styleName === this.noForm.style) || []
        this.ID = id
      }
      const { datas } = await queryColorList({ styleId: this.ID })
      this.colorOptions = datas
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // tab栏切换
    handleClick(val) {
      this.name = val.name
      // val.name === 'third' ?this.queryAll(0): val.name === 'first' ? this.queryDetail(0) : this.query(0)
      switch (val.name) {
        case 'first':
          this.form = {}
          this.queryDetail(0)
          break
        case 'second':
          this.noForm = {}
          this.query(0)
          break
        case 'third':
          this.allForm = {}
          this.queryAll(0)
          break
      }
    },
    //   供应商下拉数据
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    // 明细对账确认
    async handleDetailReconciliation() {
      try {
        this.code = ''
        this.detailLoading = true
        if (!this.form.model) {
          const { code } = await confirmCheckBillDetail({ notCheckBillDetailVOList: this.multipleSelection })
          this.code = code
        } else {
          const obj = Object.assign({}, this.queryDetailModelParams, { totalPairs: this.detailAmount })
          const { code } = await confirmCheckBillDetailByNotCheck(obj)
          this.code = code
        }
        this.code === 0 ? this.$message.success('对账成功') : this.$message.success('对账失败')
        this.detailLoading = false
        this.detailDialogVisible = false
        this.queryDetail(0)
      } catch (err) {
        this.detailLoading = false
      }
    },
    // 整单对账确认
    async handleAllReconciliation() {
      try {
        this.Loading = true
        const { code } = await confirmCheckBill({ notCheckBillVOList: this.multipleSelection })
        code === 0 ? this.$message.success('对账成功') : this.$message.success('对账失败')
        this.Loading = false
        this.dialogVisible = false
        this.queryAll(0)
      } catch {
        this.Loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.el-table .warning-row {
  color: red !important;
}
</style>
