<template>
  <div>
    <el-table
      ref="multipleTable"
      v-loading="tableLoading"
      class="mb-3"
      :data="tableData"
      :summary-method="getSummaries"
      :header-cell-style="{background:'#fafafa',color:'#606266'}"
      max-height="400px"
      show-summary
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50" />
      <el-table-column :label="$t('page.No')" type="index" width="50" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="purchaseOrderCode" label="采购订单" align="center" sortable min-width="120" />
      <el-table-column prop="vendorName" label="供应商" width="110" align="center" sortable />
      <el-table-column prop="style" label="STYLE" min-width="90" align="center" sortable />
      <el-table-column prop="number" label="采购数量" width="120" align="center" sortable />
      <el-table-column prop="unCheckBillNumber" label="未对账数量" min-width="130" align="center" sortable />
      <el-table-column prop="checkBillNumber" label="已对账数量" min-width="130" align="center" sortable />
      <el-table-column prop="purchaseTotalPrice" label="采购金额" width="110" align="center" sortable />
      <el-table-column prop="unCheckBillPrice" label="未对账金额" width="130" align="center" sortable />
      <el-table-column prop="checkBillMoney" label="已对账金额" width="130" align="center" sortable />
    </el-table>
    <div class="block">
      <pagination
        :pager="pager"
        @pagination="_turnoverpage"
      />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'

export default {
  components: {
    Pagination
  },
  props: {
    tableLoading: {
      type: Boolean,
      default: false
    },
    tableHeight: {
      type: String,
      default: ''
    },
    tableData: {
      type: Array,
      default: () => []
    },
    pager: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    handleSelectionChange(val) {
      this.$emit('getMultipleSelection', val)
    },
    _turnoverpage(val) {
      this.pager.current = val.current
      this.pager.size = val.size
      this.$emit('refresh')
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if (index >= 5 && index <= 7) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              prev = (prev + '').indexOf('.') === -1 ? prev : Number(prev.toFixed(2))
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
          }
        } else if (index > 7) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              prev = (prev + '').indexOf('.') === -1 ? prev : Number(Number(prev).toFixed(2))
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0).toFixed(2)
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    }
  }
}
</script>

<style scoped lang="scss">
</style>
