<template>
  <div>
    <el-table
      ref="multipleTable"
      v-loading="tableLoading"
      class="mb-3"
      :data="tableData"
      :summary-method="getSummaries"
      show-summary
      :header-cell-style="{ background: '#fafafa', color: '#606266' }"
      max-height="480px"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50" />
      <el-table-column :label="$t('page.No')" type="index" width="50" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="purchaseOrderCode" label="采购订单" align="center" sortable min-width="120" />
      <template v-if="flag">
        <el-table-column prop="collectCode" label="收货单号" align="center" sortable min-width="120" />
      </template>
      <el-table-column prop="vendorName" label="供应商" width="100" align="center" sortable />
      <el-table-column prop="purchaseOrderCode" label="规格型号" align="center" sortable min-width="120">
        <template slot-scope="scope">
          {{ scope.row.style }}/{{ scope.row.color }}/{{ scope.row.size }}
        </template>
      </el-table-column>
      <el-table-column prop="fnsku" label="FNSKU" align="center" sortable min-width="120" />
      <el-table-column prop="upc" label="UPC" align="center" sortable />
      <template v-if="flag">
        <el-table-column prop="collectDate" label="收货时间" width="120" align="center" sortable />
        <el-table-column prop="currencyType" label="币种" width="100" align="center" sortable>
          <template slot-scope="scope">
            <!--  <p v-if="scope.row.currencyType == '1'">美元</p>
            <p v-if="scope.row.currencyType == '2'">人民币</p> -->
            {{ getAuditTypeLabel('CURRENCY_TYPE',scope.row.currencyType) }}
          </template>
        </el-table-column>
      </template>
      <el-table-column prop="purchasePrice" label="采购单价" width="110" align="center" sortable />
      <template v-if="flag">
        <el-table-column label="实际对账单价" width="150" align="center" sortable>
          <template slot-scope="scope">
            <el-input v-model="scope.row.currPrice" @input="input(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column prop="unCheckBillNumber" label="对账数量" width="120" align="center" sortable />
        <el-table-column prop="currTotalPrice" label="对账金额" width="110" align="center" sortable />
      </template>
      <template v-if="!flag">
        <el-table-column prop="number" label="数量" width="90" align="center" sortable />
        <el-table-column prop="purchaseTotalPrice" label="金额" width="90" align="center" sortable />
      </template>
    </el-table>
    <div class="block">
      <pagination
        :pager="pager"
        :page-sizes="[100,200,500,1000]"
        @pagination="_turnoverpage"
      />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'

export default {

  components: {
    Pagination
  },
  mixins: [commodityInfoDict],
  props: {
    flag: {
      type: Boolean,
      default: true
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    tableHeight: {
      type: String,
      default: ''
    },
    tableData: {
      type: Array,
      default: () => []
    },
    pager: {
      type: Object,
      default: () => { }
    },
    model: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
    }
  },
  created() {

  },
  mounted() { },
  methods: {
    checkSelectable() {
    },
    input(row) {
      let outPairsBoo = true
      if (row.currPrice <= 0) outPairsBoo = false
      this.$refs.multipleTable.toggleRowSelection(row, outPairsBoo)
      row.currTotalPrice = (row.currPrice * row.unCheckBillNumber).toFixed(2)
      this.$nextTick(() => {
        this.getSummaries()
      })
    },
    handleSelectionChange(val) {
      this.$emit('getMultipleSelection', val)
    },
    _turnoverpage(val) {
      this.pager.current = val.current
      this.pager.size = val.size
      this.$emit('refresh')
    },
    getSummaries(param) {
      const { columns, data } = param || {}
      const sums = []
      columns && columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (this.flag) {
          if (index === 13 || index === 12) {
            const values = data.map(item => Number(item[column.property]))
            if (!values.every(value => isNaN(value))) {
              sums[index] = Number(values.reduce((prev, curr) => {
                const value = Number(curr)
                prev = (prev + '').indexOf('.') === -1 ? prev : Number(Number(prev).toFixed(2))
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)).toFixed(2)
            } else {
              sums[index] = ''
            }
          }
        } else {
          if (index === 9 || index === 8) {
            const values = data.map(item => Number(item[column.property]))
            if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr)
                prev = (prev + '').indexOf('.') === -1 ? prev : Number(Number(prev).toFixed(2))
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)
            } else {
              sums[index] = ''
            }
          }
        }
      })
      return sums
    }
  }
}
</script>

<style scoped lang="scss">
</style>
